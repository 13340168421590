import { fintocWidgetSetupInterface } from './interfaces/fintoc';
import { kushkiWidgetSetupInterface } from './interfaces/kushki';
import { genericRedirectionInterface, DOCUMENT_TYPES } from './interfaces/_shared';

const getProviderInterface = ({ provider, flowInitializationData }) => {
  const interfaceMap = {
    cl_fintoc: fintocWidgetSetupInterface,
    co_belvo: genericRedirectionInterface,
    cl_kushki: kushkiWidgetSetupInterface,
    co_kushki: kushkiWidgetSetupInterface,
  };
  return interfaceMap[provider.provider_code]({ provider, flowInitializationData });
};

export { getProviderInterface, DOCUMENT_TYPES };
